import React from 'react'
import './Seasonal.css'
function Seasonal() {
  return (
    <>
   <div class="container-fluid p-0">
      <div class="seasonal">
        <div class="container">
          <div class="row">
            <div class="col-md-12 heading text-center">
              <img src="images/fish.png" alt=""/>
              <h3 className='A-ULight' style={{fontSize:'58px'}}>Seasonal</h3>
            </div>
            
            <div class="col-md-6 p-0 ">
              <img class="barbeque" src="images/barbeque 1.jpg" alt=""/>
            </div>
            <div class="col-md-6  fish-menu A-Light">
              <ul>
                <li>
                  <div class="seafish">
                    <span><img src="images/1.png" alt=""/></span>
                    <span className='menu-name'>
                    <h5> Kofer Fish</h5>
                    <p>Description line here</p></span>
                    <span class="line1 "></span> 
                  
                  </div>
                  
                </li>

                <li>
                  <div class="seafish">
                    <span><img src="images/Layer-84.png" alt=""/></span>
                    <span className='menu-name'>
                    <h5> Sheri Fish</h5>
                    <p>Description line here</p></span>
                    <span class="line1"></span>
                  </div>
                 </li>

                 <li>
                  
                  <div class="seafish">
                    <span><img src="images/3.png" alt=""/></span>
                    <span className='menu-name'>
                    <h5> Hamour Fish</h5>
                    <p>Description line here</p></span>
                    <span class="line1"></span>
                  </div>
                 </li>
                 <li>
                  <div class="seafish">
                    <span><img src="images/farshfish.png" alt=""/></span>
                    <span className='menu-name'>
                    <h5> Frash Fish</h5>
                    <p>Description line here</p></span>
                    <span class="line1"></span>
                  </div>
                 </li>
                 <li>
                  <div class="seafish">
                    <span><img src="images/5.png" alt=""/></span>
                    <span className='menu-name' >
                    <h5>Grilled Seabass  Fish</h5>
                    <p>Description line here</p></span>
                    <span class="line1"></span>
                  </div>
                 </li>
                 <li>
                  <div class="seafish">
                    <span><img src="images/6.png" alt=""/></span>
                    <span className='menu-name'>
                    <h5>Grilled Subrem   Fish</h5>
                    <p>Description line here</p></span>
                    <span class="line1"></span>
                  </div>
                 </li>

                 <li>
                  <div class="seafish">
                    <span><img src="images/7.png" alt=""/></span>
                    <span className='menu-name'>
                    <h5> Shrimp</h5>
                    <p>Description line here</p></span>
                    <span class="line1"></span>
                  </div>
                 </li> 
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Seasonal